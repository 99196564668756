import { Loader } from "@googlemaps/js-api-loader";

const apikey = process.env.VUE_APP_GCP_ACCESS;
const searchPlaces = async (query) => {
  const loader = new Loader({
    apiKey: apikey,
    version: "weekly",
    libraries: ["places"],
  });

  try {
    const google = await loader.load();

    const autocompleteService = new google.maps.places.AutocompleteService();
    const placesService = new google.maps.places.PlacesService(
      document.createElement("div")
    );

    return new Promise((resolve, reject) => {
      autocompleteService.getPlacePredictions(
        {
          input: query,
        },
        async (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const placeDetailsPromises = predictions.map((prediction) => {
              return new Promise((innerResolve, innerReject) => {
                placesService.getDetails(
                  {
                    placeId: prediction.place_id,
                    fields: ["address_components"],
                  },
                  (place, detailStatus) => {
                    if (
                      detailStatus === google.maps.places.PlacesServiceStatus.OK
                    ) {
                      const postalCodeComponent = place.address_components.find(
                        (component) => component.types.includes("postal_code")
                      );

                      const cityComponent = place.address_components.find(
                        (component) => component.types.includes("locality")
                      );

                      const postalCode = postalCodeComponent
                        ? postalCodeComponent.short_name
                        : null;
                      const city = cityComponent
                        ? cityComponent.long_name
                        : null;

                      const result = {
                        description: prediction.description,
                        postalCode: postalCode,
                        city: city,
                      };

                      innerResolve(result);
                    } else {
                      innerReject(detailStatus);
                    }
                  }
                );
              });
            });

            const placeDetails = await Promise.all(placeDetailsPromises);
            console.log("PLACE DETAILS", placeDetails);
            resolve(placeDetails);
          } else {
            console.error("Prediction request failed with status:", status);
            reject(status);
          }
        }
      );
    });
  } catch (error) {
    console.error("Error loading Google Maps API:", error);
    throw error;
  }
};

export const searchPlacesExtra = async (query) => {
  const loader = new Loader({
    apiKey: apikey,
    version: "weekly",
    libraries: ["places"],
  });

  try {
    const google = await loader.load();
    var geocoder = new google.maps.Geocoder();
    const autocompleteService = new google.maps.places.AutocompleteService();

    return new Promise((resolve, reject) => {
      autocompleteService.getPlacePredictions(
        { input: query },
        function (predictions, status) {
          if (status == "OK") {
            const responsePromises = [];

            for (const prediction of predictions) {
              responsePromises.push(
                new Promise((resolvePrediction, rejectPrediction) => {
                  geocoder.geocode(
                    {
                      placeId: prediction.place_id,
                    },
                    function (responses, status) {
                      if (status == "OK") {
                        resolvePrediction(responses[0]);
                      } else {
                        rejectPrediction(new Error("Geocode request failed"));
                      }
                    }
                  );
                })
              );
            }

            Promise.all(responsePromises)
              .then((responses) => {
                console.log(responses);
                resolve(responses); // Resolve the promise with an array of responses
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject(new Error("Place predictions request failed"));
          }
        }
      );
    });
  } catch (error) {
    console.error("Error loading Google Maps API:", error);
    throw error;
  }
};

export default searchPlaces;
