<template>
  <div class="border-t border-gray-100 flex justify-center mt-24">
    <div
      class="flex bg-white items-center space-x-4 lg:px-6 px-2 xl:w-5/6 w-full pt-8 pb-24"
    >
      <div class="w-full flex">
        <div class="grid grid-cols-4 gap-10">
          <div class="flex flex-col items-center space-y-3">
            <img
              src="@/assets/logo-simple.jpeg"
              class="w-20 rounded-xl shadow-md"
            />
            <p class="text-center text-gray-400">
              Indulge the Finest Halal Delights, Delivered to Your Doorstep!
            </p>
          </div>
          <div class="space-y-2">
            <p class="font-medium pb-2 cursor-pointer">Get to Know Us</p>
            <p class="text-gray-500 cursor-pointer focus:underline">About us</p>

            <p class="text-gray-500 cursor-pointer focus:underline">
              <a href="http://blog.vneed.com/"> Company Blog</a>
            </p>
          </div>
          <div class="space-y-2">
            <p class="font-medium pb-2 cursor-pointer">Let Us Help You</p>
            <router-link to="/customer/account">
              <p class="text-gray-500 cursor-pointer focus:underline">
                Account Details
              </p>
            </router-link>
            <router-link to="/customer/orders">
              <p class="text-gray-500 cursor-pointer focus:underline">
                Order History
              </p>
            </router-link>

            <router-link to="/customer/get-help">
              <p class="text-gray-500 cursor-pointer focus:underline">Help</p>
            </router-link>
          </div>
          <div class="space-y-2">
            <p class="font-medium pb-2 cursor-pointer">Doing Business</p>
            <router-link to="/partner/joinrider">
              <p class="text-gray-500 cursor-pointer focus:underline">
                Become a Driver
              </p>
            </router-link>
            <router-link to="/partner/homechef">
              <p class="text-gray-500 cursor-pointer focus:underline">
                Become a Home Chef
              </p>
            </router-link>
            <router-link to="/partner/joinconvenience">
              <p class="text-gray-500 cursor-pointer focus:underline">
                List your store
              </p>
            </router-link>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
