<template>
  <div>
    <transition>
      <div v-if="isModalVisible">
        <Toast
          :open="isSuccess"
          :messageText="'Otp Sent Successfully'"
          :varient="'success'"
        />

        <section
          v-if="!isForgotPassword"
          class="absolute left-0 top-0 h-[100vh] w-full"
        >
          <div
            @click="onToggle"
            class="absolute opacity-70 inset-0 z-30 bg-black"
            :class="isOpen ? 'overflow-hidden' : ''"
          ></div>

          <div
            class="overflow-y-auto absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 w-full max-w-lg rounded-xl shadow-lg bg-white z-40"
            :class="mode === 'signin' ? 'h-[85vh]' : 'h-[95vh]'"
          >
            <button @click="onToggle" class="absolute top-4 right-4">
              <img src="@/assets/svg/close.svg" alt="" />
            </button>
            <div class="px-4 py-6">
              <h3 class="font-bold text-3xl text-[#191919] leading-10">
                Sign in or Sign up
              </h3>
            </div>
            <div
              v-if="mode === 'signin'"
              class="h-11 bg-[#ECFCFC] flex items-center justify-center"
            >
              <h6 class="font-normal text-bsm text-[#628181] leading-5">
                Sign in to access your credits and discounts
              </h6>
            </div>

            <!-- Toggle Signin / Sign Up -->
            <div
              v-if="mode !== 'verification'"
              class="flex justify-center items-center"
            >
              <div class="flex border-1 rounded-full border-grey-200 my-8">
                <div
                  v-for="(type, index) in this.types"
                  :key="index"
                  @click="changeActiveButton(type)"
                  :class="
                    mode === type.id
                      ? ' bg-black-300 text-white border-white'
                      : ''
                  "
                  class="rounded-full px-4 py-1 cursor-pointer flex justify-center items-center"
                >
                  <p class="text-bsm font-medium">
                    <strong>{{ type.mode }}</strong>
                  </p>
                </div>
              </div>
            </div>
            <!-- SIGN IN STEP -->

            <div v-if="mode === 'signin'" class="px-6 pb-4">
              <form id="app" @submit="login" method="post">
                <div class="flex flex-col">
                  <label
                    :class="!validEmail && 'text-red'"
                    class="font-semibold pb-2"
                    v-if="validEmail"
                  >
                    Email
                  </label>
                  <label
                    :class="!validEmail && 'text-red'"
                    class="font-semibold pb-2"
                    v-else
                  >
                    {{ validationError.email }}
                  </label>

                  <input
                    placeholder="Required"
                    class="w-full bg-gray-150 rounded-lg text-lg py-2 px-4"
                    v-model="email"
                  />
                </div>

                <div class="flex flex-col pt-4 relative">
                  <label
                    v-if="validPassword"
                    :class="!validPassword && 'text-red'"
                    class="font-semibold pb-2"
                  >
                    Password
                  </label>

                  <label
                    :class="!validPassword && 'text-red'"
                    class="font-semibold pb-2"
                    v-else
                  >
                    {{ validationError.password }}
                  </label>

                  <input
                    placeholder="*****"
                    class="w-full bg-gray-150 rounded-lg text-lg py-2 px-4"
                    :type="ispassShow ? 'text' : 'password'"
                    v-model="password"
                  />
                  <button
                    class="absolute right-4 top-[60px] font-semibold"
                    type="button"
                    @click="passwordToggle"
                  >
                    {{ ispassShow ? "Hide" : "Show" }}
                  </button>
                </div>

                <button
                  @click="forgotpasswordtoogle"
                  type="button"
                  class="mt-2"
                >
                  Forgot Password ?
                </button>

                <button
                  class="flex justify-center outline-none bg-red w-full rounded-full capitalize mt-8 py-2.5 text-white disabled:bg-gray-800 disabled:cursor-not-allowed"
                  type="submit"
                  value="Submit"
                  :disabled="isFormValid === false"
                  v-if="isloading"
                >
                  <Spinner />
                </button>
                <button
                  class="outline-none bg-red w-full rounded-full capitalize font-semibold mt-8 py-2.5 text-white disabled:bg-gray-800 disabled:cursor-not-allowed"
                  type="submit"
                  value="Submit"
                  :disabled="isFormValid === false"
                  v-else
                >
                  Continue to Sign in
                </button>
              </form>
              <!-- TNC CONTAINER -->
              <div class="my-4">
                <p class="text-sm text-grey-600 font-normal">
                  By continuing with the sign in process, we may send you a
                  one-time verification code via text message to the phone
                  number associated with your account. Message and data rates
                  may apply.
                </p>
              </div>
            </div>

            <!-- SIGN UP STEP -->

            <div v-if="mode === 'signup'" class="px-6 pb-4">
              <form id="app" @submit="createUser" method="post">
                <div
                  class="flex items-center gap-4 mb-4 flex-wrap md:flex-nowrap w-full"
                >
                  <div class="flex flex-col w-full">
                    <label class="font-semibold pb-2"> First Name </label>

                    <input
                      placeholder="First Name"
                      class="w-full bg-gray-150 rounded-lg text-lg py-2 px-4"
                      v-model="firstName"
                    />
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-semibold pb-2"> Last Name </label>

                    <input
                      placeholder="Last Name"
                      class="w-full bg-gray-150 rounded-lg text-lg py-2 px-4"
                      v-model="lastName"
                    />
                  </div>
                </div>

                <div class="flex flex-col">
                  <label
                    :class="!validEmail && 'text-red'"
                    class="font-semibold pb-2"
                    v-if="validEmail"
                  >
                    Email
                  </label>
                  <label
                    :class="!validEmail && 'text-red'"
                    class="font-semibold pb-2"
                    v-else
                  >
                    {{ validationError.email }}
                  </label>

                  <input
                    placeholder="Required"
                    class="w-full bg-gray-150 rounded-lg text-lg py-2 px-4"
                    v-model="email"
                  />
                </div>

                <div class="flex items-center gap-4">
                  <div class="flex flex-col gap-1">
                    <label for="country" class="font-semibold pb-2"
                      >Country</label
                    >
                    <select
                      id="country"
                      class="w-28 rounded-md bg-coolGray-100 p-2 pl-2"
                      @change="handleCountryCode"
                    >
                      <option value="+1">+1 (US)</option>
                      <option value="+1">+1 (CA)</option>
                      <option value="+61">+61 (AU)</option>
                      <option value="+91">+91 (IND)</option>
                    </select>
                  </div>

                  <div class="flex flex-col w-full my-2">
                    <label for="mobilenumber" class="font-semibold pb-2"
                      >Phone Number</label
                    >
                    <input
                      @blur="isValid"
                      v-model="phone"
                      type="tel"
                      placeholder="(884) 554-191"
                      class="w-full rounded-md bg-coolGray-100 p-2 pl-2"
                      id="mobilenumber"
                    />
                  </div>
                </div>

                <div class="flex flex-col pt-4 relative">
                  <label
                    v-if="validPassword"
                    :class="!validPassword && 'text-red'"
                    class="font-semibold pb-2"
                  >
                    Password
                  </label>

                  <label
                    :class="!validPassword && 'text-red'"
                    class="font-semibold pb-2"
                    v-else
                  >
                    {{ validationError.password }}
                  </label>

                  <input
                    placeholder="*****"
                    class="w-full bg-gray-150 rounded-lg text-lg py-2 px-4"
                    :type="ispassShow ? 'text' : 'password'"
                    v-model="password"
                  />
                  <button
                    class="absolute right-4 top-[60px] font-semibold"
                    type="button"
                    @click="passwordToggle"
                  >
                    {{ ispassShow ? "Hide" : "Show" }}
                  </button>
                </div>
                <!-- TNC CONTAINER -->
                <div class="mt-4">
                  <p class="text-bsm text-grey-600 font-semibold text-center">
                    By tapping “Sign Up” , you agree to Vneed's
                    <button type="button" @click="showTnc">
                      Terms and Conditions
                    </button>
                    and
                    <button type="button" @click="showpp">
                      Privacy Policy.
                    </button>
                  </p>
                </div>

                <button
                  class="flex justify-center outline-none bg-red w-full rounded-full capitalize mt-4 py-2.5 text-white disabled:bg-gray-800 disabled:cursor-not-allowed"
                  type="submit"
                  value="Submit"
                  :disabled="isFormValid === false"
                  v-if="isloading"
                >
                  <Spinner />
                </button>
                <button
                  class="outline-none bg-red w-full rounded-full capitalize font-semibold mt-4 py-2.5 text-white disabled:bg-gray-800 disabled:cursor-not-allowed"
                  type="submit"
                  value="Submit"
                  :disabled="isFormValid === false"
                  v-else
                >
                  Sign Up
                </button>
              </form>
            </div>

            <!-- MOBILE VERIFICATION STEP -->

            <div v-if="mode === 'verification'" class="px-6 pb-4">
              <form id="verify" @submit="verifyNumber" method="post">
                <div>
                  <div class="px-4 py-6">
                    <h3 class="font-bold text-3xl text-[#191919] leading-10">
                      Verify Mobile Number
                    </h3>
                    <h5
                      class="text-bsm font-medium leading-8 text-grey-600 text-center mb-5"
                    >
                      For your security, we want to make sure it’s really you.
                    </h5>
                  </div>

                  <section>
                    <div class="mb-4">
                      <p
                        class="text-red text-sm"
                        v-if="phoneisInValid === true"
                      >
                        Please Enter a valid Security Code
                      </p>
                    </div>
                    <div class="flex flex-col items-center gap-4">
                      <div class="flex flex-col w-full gap-2">
                        <label for="verify" class="text-base font-bold"
                          >Enter 6-digit code</label
                        >
                        <input
                          @blur="isValid"
                          v-model="code"
                          type="tel"
                          placeholder="* * * * * *"
                          class="w-full rounded-md bg-coolGray-100 p-2 pl-4"
                          id="verify"
                        />
                      </div>
                      <h5
                        class="text-bsm font-medium leading-8 text-grey-600 text-left"
                      >
                        We sent a code to {{ formatedNumner }}
                      </h5>

                      <div class="flex items-center gap-2">
                        <p
                          class="text-black-300 text-sm underline cursor-pointer"
                        >
                          Resend Code
                        </p>
                        <p
                          class="text-black-300 text-sm underline cursor-pointer"
                        >
                          Receive a Code Via Call
                        </p>
                        <p
                          class="text-black-300 text-sm underline cursor-pointer"
                        >
                          Get Help
                        </p>
                      </div>
                    </div>
                  </section>

                  <div class="w-100 flex gap-2 justify-center mt-4">
                    <button
                      :class="
                        phoneisInValid
                          ? 'btn-disabled bg-gray-200 hover:cursor-not-allowed'
                          : 'bg-red'
                      "
                      class="bg-red text-white p-2 px-4 rounded-full text-base font-medium cursor-pointer w-full text-center flex items-center justify-center"
                      for="verifynumber-modal"
                      v-if="isloading"
                    >
                      <Spinner />
                    </button>
                    <button
                      :class="
                        phoneisInValid
                          ? 'btn-disabled bg-gray-200 hover:cursor-not-allowed'
                          : 'bg-red'
                      "
                      class="bg-red text-white p-2 px-4 rounded-full text-base font-medium cursor-pointer w-full text-center"
                      for="verifynumber-modal"
                      v-else
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <section v-else class="absolute left-0 top-0 h-[100vh] w-full">
          <div
            @click="onToggle"
            class="absolute opacity-70 inset-0 z-30 bg-black"
            :class="isOpen ? 'overflow-hidden' : ''"
          ></div>

          <div
            class="overflow-y-auto absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 w-full max-w-lg rounded-xl shadow-lg bg-white z-40"
            :class="'h-[400px]'"
          >
            <button @click="onToggle" class="absolute top-4 right-4">
              <img src="@/assets/svg/close.svg" alt="" />
            </button>
            <div class="px-4 py-6">
              <h3 class="font-bold text-3xl text-[#191919] leading-10">
                Forgot Password ?
              </h3>
              <h5>Its Easy to Reset !</h5>
            </div>

            <!-- SIGN IN STEP -->

            <div class="px-6 pb-4">
              <form id="app" @submit="login" method="post">
                <div class="flex flex-col">
                  <label
                    :class="!validEmail && 'text-red'"
                    class="font-semibold pb-2"
                    v-if="validEmail"
                  >
                    Email
                  </label>
                  <label
                    :class="!validEmail && 'text-red'"
                    class="font-semibold pb-2"
                    v-else
                  >
                    {{ validationError.email }}
                  </label>

                  <input
                    placeholder="Required"
                    class="w-full bg-gray-150 rounded-lg text-lg py-2 px-4"
                    v-model="email"
                  />
                </div>

                <button
                  @click="forgotpasswordtoogle"
                  type="button"
                  class="mt-2"
                >
                  Login using Passoword
                </button>

                <button
                  class="flex justify-center outline-none bg-red w-full rounded-full capitalize mt-8 py-2.5 text-white disabled:bg-gray-800 disabled:cursor-not-allowed"
                  type="submit"
                  value="Submit"
                  :disabled="isFormValid === false"
                  v-if="isloading"
                >
                  <Spinner />
                </button>
                <button
                  class="outline-none bg-red w-full rounded-full capitalize font-semibold mt-8 py-2.5 text-white disabled:bg-gray-800 disabled:cursor-not-allowed"
                  type="submit"
                  value="Submit"
                  v-else
                >
                  Reset Password
                </button>
              </form>
              <!-- TNC CONTAINER -->
              <div class="my-4">
                <p class="text-sm text-grey-600 font-normal">
                  Please Check Your mail We will send the reset link at you
                  inbox please also check the spam too
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import router from "../../../router";
import Spinner from "../../../components/reusable/Spinner.vue";
import sendVerificationCode from "../../../helpers/sendOtp.js";
import Toast from "@/components/reusable/Toast.vue";
import tnc from "@/assets/tnc/tnc.pdf";
import pp from "@/assets/tnc/privacypolicy.pdf";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isForgotPassword: false,
      isSuccess: false,
      isError: false,

      isOpen: false,

      validEmail: true,
      validPassword: true,
      isloading: false,
      isFormValid: false,

      ispassShow: false,
      email: "",
      password: "",
      errors: [],
      validationError: {
        email: "",
        password: "",
      },

      firstName: "",
      lastName: "",
      countryCode: "",
      phone: "",
      formatedNumner: "",
      phoneisInValid: false,

      userCreated: false,
      userVerified: false,
      code: "",
    };
  },

  props: [
    "activeSignup",
    "activeSignin",
    "isModalVisible",
    "mode",
    "types",
    "changeActiveButton",
    "onToggle",
  ],

  computed: {
    isValidEmail() {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email);
    },
  },

  methods: {
    ...mapActions(["register"]),

    forgotpasswordtoogle() {
      this.isForgotPassword = !this.isForgotPassword;
    },

    async sendOtp() {
      let status = await sendVerificationCode(
        this.countryCode,
        this.removeExtraCharacters(this.phone)
      );

      this.isOtpSent = { status: status };

      setTimeout(() => {
        this.isOtpSent = {
          status: "",
        };
      }, 2000);
    },

    removeExtraCharacters(number) {
      // Remove all non-digit characters from the input string
      const cleanedNumber = number.replace(/\D/g, "");

      // If the number doesn't match the USA mobile number format, return the original input
      return cleanedNumber;
    },

    handleCountryCode(e) {
      this.countryCode = e.target.value;
    },

    async login(e) {
      if (e) {
        e.preventDefault();
      }
      this.isloading = true;
      let data = {
        userNameOrEmailAddress: this.email,
        password: this.password,
      };

      const response = await axios
        .post("TokenAuth/Authenticate", data, {
          headers: {
            "Abp.TenantId": "3",
          },
        })
        .then((res) => {
          this.isloading = false;

          this.userCreated = true;
          this.onToggle();
          sessionStorage.setItem("token", res?.data?.result?.accessToken);
          localStorage.setItem("loggedIn", "true");
          localStorage.setItem("token", res?.data?.result?.accessToken);

          this.$swal({
            title: "Welcome to Vneed!",
            text: "Your one stop halal market place!",
            imageUrl: require("../../../assets/logo-white.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            imageAlt: "Custom Icon",
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Explore Vneed!", // Change button text
            showCloseButton: true,
          });
          axios.defaults.headers[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("token")}`;
          if (this.$route.name === "main") {
            router.go(0);
          } else {
            router.push("/customer/main");
          }

          return true;
        })
        .catch((error) => {
          this.isloading = false;
          this.$swal({
            title: error.response.data.error.message,
            text: error.response.data.error.details,
            imageUrl: require("../../../assets/logo-simple.jpeg"), // Replace with your icon's URL
            imageWidth: 100, // Adjust as needed
            imageHeight: 100,
            showCancelButton: false,
            confirmButtonColor: "#d1311F", // Change button color
            confirmButtonText: "Try Again!", // Change button text
            showCloseButton: true,
          });
          return false;
        });
      return response?.response;
    },

    async createUser(e) {
      e.preventDefault();

      this.isloading = true;

      let data = {
        name: this.firstName,
        surname: this.lastName,
        userName: this.email,
        emailAddress: this.email,
        password: this.password,
        isActive: true,
        phoneNumber: `${this.countryCode}${this.formatedNumber}`,
        roleNames: ["customer"],
      };

      await this.register(data)
        .then(() => {
          this.isLoader = false;
          this.isloading = false;
          this.login();
          setTimeout(() => {
            this.isSuccess = true;
          }, 1000);
          if (this.$route.name === "main") {
            this.$router.go(0);
          }
        })
        .catch((error) => {
          if (error) {
            this.isLoader = false;
            this.isloading = false;
            this.isError = true;
            this.$swal({
              title: error.response.data.error.message,
              text: error.response.data.error.details,
              imageUrl: require("../../../assets/logo-simple.jpeg"), // Replace with your icon's URL
              imageWidth: 100, // Adjust as needed
              imageHeight: 100,
              showCancelButton: false,
              confirmButtonColor: "#d1311F", // Change button color
              confirmButtonText: "Try Again!", // Change button text
              showCloseButton: true,
            });
            setTimeout(() => {
              this.isError = false;
            }, 2000);
            this.isSuccess = false;
          }
        });
    },

    passwordToggle() {
      this.ispassShow = !this.ispassShow;
    },

    isValid() {
      var phoneRe = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

      let test = phoneRe.test(this.phone);
      if (test === false) {
        this.phoneisInValid = true;
      } else {
        this.phoneisInValid = false;

        this.phone = this.formatPhoneNumber(this.phone);
        this.formatedNumber = `${this.countryCode}${this.phone}`;
      }
    },

    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = match[1] ? "+1 " : "";

        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return null;
    },

    verifyNumber(e) {
      e.preventDefault();

      this.isloading = true;
      const payload = new FormData();
      payload.append("confirmationCode", this.code);
      payload.append(
        "phoneNumber",
        `${this.countryCode}${this.removeExtraCharacters(this.phone)}`
      );

      axios
        .post("services/app/Customers/ConfirmPhoneNumber", payload, {
          headers: {
            "Abp.TenantId": "3",
            "Content-Type": " application/json-patch+json",
            accept: "text/plain",
            "X-XSRF-TOKEN":
              "CfDJ8JKBztXgYutIjf5jyWsD5-wK6jcFiZbwQFgCUct6OGCPROCL5ZXwb96gWYDpR9-yx2pkVnmqDdHrX_jzI0wtkPOLw3brwAi5a-EkRGyBCnrCGQz3NAeysns2FTZ5wpoe12Yj3zsanliqFq9WeEYDDoc",
          },
        })
        .then(() => {
          this.isloading = false;

          this.isSuccess = true;
          this.login();
          setTimeout(() => {
            this.isSuccess = false;
            this.iserror = false;
          }, 3000);
        })
        .catch(() => {
          this.isloading = false;
          this.iserror = true;
          setTimeout(() => {
            this.isSuccess = false;
            this.iserror = false;
          }, 3000);
        });
    },

    showTnc() {
      const link = document.createElement("a");
      link.href = tnc;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    showpp() {
      const link = document.createElement("a");
      link.href = pp;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },

  watch: {
    password(value) {
      if (value === "") {
        this.validationError.password = "Password required.";
        this.validPassword = false;

        this.isFormValid = false;
      } else {
        this.validPassword = true;
        this.validationError = {
          password: "",
          ...this.validationError,
        };
        if (this.validEmail && this.validPassword) {
          this.isFormValid = true;
        }
      }
    },
    email(value) {
      var email = /^[^@]+@\w+(\.\w+)+\w$/.test(value);
      if (email === false) {
        this.validEmail = false;
        this.validationError.email = "Please enter a valid email";

        this.isFormValid = false;
      } else if (value === "") {
        this.validEmail = false;

        this.isFormValid = false;

        this.validationError.email = "Email cannot be Blank";
      }

      if (email) {
        this.validEmail = true;

        if (this.validEmail && this.validPassword) {
          this.isFormValid = true;
        }
      }
    },
    phone() {
      this.isValid();
    },
  },

  components: { Spinner, Toast },
};
</script>
<style>
/* .fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 100ms ease-out;
} */
</style>
