<template>
  <div class="bg-gray-400 p-3 rounded-lg flex items-start space-x-3">
    <div>
      <img src="@/assets/logo-simple.jpeg" class="w-6" />
    </div>
    <div class="w-full">
      <p class="text-sm text-white">5% back on Pickup orders with DashPass</p>
      <p class="text-xs text-white pt-2">
        DashPass subscribers get 5% credits back on eligible Pickup orders,
        delivery savings and exclusive offers.
      </p>
      <SfButton class="text-black bg-white shadow-md rounded-full text-sm font-medium capitalize p-2 mt-2">
        Get Vneed Pass
      </SfButton>
    </div>
    <div>
      <img src="@/assets/svg/white-cross.svg" alt="icom" class="w-5 cursor-pointer" />
    </div>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';

export default { components: { SfButton } };
</script>

<style>
</style>