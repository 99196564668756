<template>
  <LandingLayout>
    <div
      class="flex flex-col gap-1 justify-center items-center h-full py-12 px-4"
    >
      <div
        class="lg:w-4/12 md:w-1/2 w-full p-5 border border-gray-100 rounded-md shadow-md"
      >
        <div class="w-full flex items-start">
          <router-link to="/customer/account">
            <button class="text-left text-primary">Go Back</button></router-link
          >
        </div>
        <h1 class="text-3xl font-semibold py-6">Manage Account</h1>
        <h1 class="text-lg font-semibold">Account Data</h1>
        <p class="text-gray-400 font-light text-sm py-2">
          An archive of your personal data is available upon request. We'll let
          you know when it's available for download.
        </p>
        <p class="text-primaryGreen font-bold border-b border-gray-100 pb-5">
          Request Archive
        </p>
        <h1 class="text-lg font-semibold pt-5">Delete Data</h1>

        <p class="text-gray-400 font-light text-sm py-2">
          You can ask to have your account and personal data erased.
        </p>
        <router-link to="/customer/manage-account/deleteaccount">
          <p class="text-primaryGreen font-bold pb-5">Delete Archive</p>
        </router-link>
      </div>
    </div>
  </LandingLayout>
</template>

<script>
import LandingLayout from "../../../layouts/landingLayout.vue";
export default {
  components: {
    LandingLayout,
  },
};
</script>

<style></style>
