<template>
  <PartnerLayout>
    <div class="h-screen">
      <div class="bg-red  flex justify-center">
        <div class="lg:w-1/2 md:w-2/3 w-full flex">
          <div class="xl:w-10/12 w-full md:px-0 px-6 -mb-6">
            <p class="text-white md:text-4xl text-3xl md:pr-12 pr-0 font-semibold lg:pt-12 md:pt-16 pt-4 md:pb-12 pb-6">
              Access new customers with VNEED
            </p>
            <RegisterStore @register="(value) => registerAdmin(value)" />
          </div>
        </div>
      </div>
      <div>
        <h1 class="text-center xl:text-5xl text-xl font-semibold lg:pt-32 pt-24">
          Turn up your online ordering
        </h1>
        <p class="text-center text-lg font-medium py-4">
          With Vneed flexible growth solutions for restaurants, you choose
          exactly how you want to partner with us.
        </p>
      </div>
    </div>
  </PartnerLayout>
</template>

<script>
import PartnerLayout from "@/layouts/partnerLayout.vue";
import RegisterStore from "@/components/auth/partner/registerStore";
import { mapActions } from "vuex";
export default {
  components: {
    PartnerLayout,
    RegisterStore,
  },
  methods: {
    ...mapActions(["registerAdmin"]),
  },
};
</script>

<style>
</style>