<template>
  <LandingLayout>
    <MobileNUmberModal
      :updateNumber="updateNumber"
      :mobileNumber="mobileNumber"
      @close="handlePopUpClose"
    />
    <div class="flex flex-col items-center space-y-10 py-10">
      <div class="border border-gray-100 lg:w-7/12 md:w-10/12 w-full">
        <div class="grid grid-cols-4 border-b border-gray-100 p-5">
          <p class="text-2xl">Profile</p>
          <div class="col-span-3 flex items-center space-x-3 justify-end pl-12">
            <!-- <p class="text-red font-semibold">Link Facebook</p> -->
            <p class="text-primaryGreen font-semibold">Change Password</p>
            <router-link to="/customer/manage-account">
              <p class="text-primaryGreen font-semibold">Manage Account</p>
            </router-link>
          </div>
        </div>
        <div class="p-5">
          <FormulateForm @submit="submitHandler">
            <div class="grid md:grid-cols-2 grid-cols-1 gap-10">
              <FormulateInput
                type="text"
                name="firstName"
                label="First name"
                v-model="formValues.firstName"
                validation="required"
              />
              <FormulateInput
                type="text"
                name="lastName"
                label="Last name"
                v-model="formValues.lastName"
                validation="required"
              />
              <FormulateInput
                type="text"
                name="email"
                label="Email"
                v-model="formValues.email"
                validation="required"
              />

              <div class="grid gap-6">
                <!-- formValues.phoneNumber -->

                <div class="w-full">
                  <div class="flex justify-between items-center">
                    <label for="">Phone_Number</label>
                    <button class="text-sm text-red">
                      <label for="mobilenumber-modal"
                        >Change Phone Number</label
                      >
                    </button>
                  </div>
                  <div
                    class="rounded-md px-4 flex items-center justify-start w-full h-10 bg-grey-100"
                  >
                    <p>{{ formValues.phoneNumber }}</p>
                  </div>
                </div>
              </div>

              <FormulateInput
                type="select"
                name="country"
                label="Country"
                v-model="formValues.country"
                :options="countryArr"
                validation="required"
              />
              <div>
                <FormulateInput
                  type="text"
                  name="address"
                  label="Address"
                  v-model="formValues.address"
                  :options="countryArr"
                  validation="required"
                />
                <label
                  class="mt-1 cursor-pointer text-primaryGreen"
                  for="address-modal"
                >
                  Change Address
                </label>
              </div>

              <FormulateInput
                type="text"
                name="city"
                label="City"
                v-model="formValues.city"
                validation="required"
              />

              <FormulateInput
                type="text"
                name="zip"
                label="Zip / Postal Code"
                v-model="formValues.postCode"
                validation="required"
              />
            </div>
            <div class="flex items-center justify-between pt-8">
              <div class="form-control">
                <label class="label cursor-pointer">
                  <input
                    type="checkbox"
                    checked="checked"
                    class="checkbox checkbox-secondary"
                  />
                  <span class="label-text text-sm font-semibold pl-4"
                    >Receive order status updates via text</span
                  >
                </label>
              </div>
              <div>
                <SfButton
                  v-if="isLoading"
                  class="bg-red rounded-full flex items-center justify-center py-3"
                >
                  <Spinner />
                </SfButton>

                <SfButton
                  :disabled="
                    getProfileInfo?.phoneNumber !== formValues.phoneNumber
                  "
                  v-else
                  :class="
                    getProfileInfo?.phoneNumber !== formValues.phoneNumber
                      ? 'bg-grey-100 pointer-events-none cursor-not-allowed'
                      : 'bg-primaryGreen'
                  "
                  class="rounded-full"
                  type="submit"
                >
                  Save
                </SfButton>
              </div>
            </div>
          </FormulateForm>
        </div>
      </div>
      <div class="border border-gray-100 lg:w-6/12 md:w-10/12 w-full">
        <p class="border-b border-gray-100 p-5 text-2xl">Privacy</p>
        <div class="p-5">
          <p>
            Vneed protects your privacy and personal information. You can choose
            to share your information with businesses so they can send you
            promotions and emails.
          </p>
          <p class="py-3 text-lg font-bold">Marketing Choices</p>
          <p>Learn about and control personalized ads.</p>
        </div>
      </div>
      <addressModal @close="handlePopUpClose" />

      <verifyNumber
        :number="formValues.phoneNumber"
        :countryCode="formValues.phoneCountryCode"
        @close="handlePopUpClose"
      />
    </div>
  </LandingLayout>
</template>

<script>
import addressModal from "@/components/reusable/modals/addressModal.vue";

import LandingLayout from "@/layouts/landingLayout.vue";
import Spinner from "@/components/reusable/Spinner.vue";
import verifyNumber from "@/components/reusable/modals/verifyNumber.vue";
import sendVerificationCode from "../../../helpers/sendOtp";
import countries from "../../../helpers/countries";
import { SfButton } from "@storefront-ui/vue";
import { mapActions, mapGetters } from "vuex";
import MobileNUmberModal from "@/components/reusable/modals/mobileNumberModal.vue";

export default {
  data() {
    return {
      validation: {},
      countryArr: countries.map((val) => val?.country),
      countryCodeArr: countries.map((val) => val?.code),
      countries: countries,
      isLoading: false,
      formValues: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        country: "",
        phoneCountryCode: "",
        address: "",
        postCode: "",
        city: "",
      },
      isOtpSent: {
        status: "",
      },
    };
  },
  components: {
    LandingLayout,
    SfButton,
    Spinner,
    verifyNumber,
    addressModal,
    MobileNUmberModal,
  },
  async created() {
    this.SetCountries();
    await this.getCustomerProfile();
    this.formValues.firstName = this.getProfileInfo?.name;
    this.formValues.lastName = this.getProfileInfo?.surname;
    this.formValues.email = this.getProfileInfo?.email;
    this.formValues.phoneNumber = this.getProfileInfo?.phoneNumber;
    this.formValues.country = this.getProfileInfo?.state;
    this.formValues.phoneCountryCode = this.getProfileInfo?.phoneCountryCode;
    this.formValues.address = this.getProfileInfo?.address;
    this.formValues.city = this.getProfileInfo?.city;
    this.formValues.postCode = this.getProfileInfo?.postCode;
  },
  methods: {
    ...mapActions([
      "SetUser",
      "getCustomerProfile",
      "SetCountries",
      "UpdateCustomerProfile",
    ]),
    removeExtraCharacters(number) {
      // Remove all non-digit characters from the input string
      const cleanedNumber = number.replace(/\D/g, "");

      // If the number doesn't match the USA mobile number format, return the original input
      return cleanedNumber;
    },
    async sendOtp() {
      let status = await sendVerificationCode(
        `${this.formValues.phoneCountryCode}`,
        this.removeExtraCharacters(this.formValues.phoneNumber)
      );

      this.isOtpSent = { status: status };

      setTimeout(() => {
        this.isOtpSent = {
          status: "",
        };
      }, 2000);
    },
    async submitHandler() {
      this.isLoading = true;
      var updatedUser = {
        name: this.formValues.firstName,
        surname: this.formValues.lastName,
        email: this.formValues.email,
        phoneNumber: this.formValues.phoneNumber,
        state: this.formValues.country,
        phoneCountryCode: this.formValues.phoneCountryCode,
        city: this.formValues.city,
        postCode: this.formValues.postCode,
      };
      await this.UpdateCustomerProfile(updatedUser);
      this.isLoading = false;
    },
    handlePopUpClose() {
      this.$emit("popUpClose");
    },

    limitPhoneNumber() {
      // Remove any non-digit characters from the phone number
      this.formValues.phoneNumber = this.formValues.phoneNumber.replace(
        /\D/g,
        ""
      );

      // Limit the phone number to 10 digits
      if (this.formValues.phoneNumberlength > 10) {
        this.formValues.phoneNumber = this.formValues.phoneNumber.substring(
          0,
          10
        );
      }
    },
  },
  computed: {
    ...mapGetters(["getProfileInfo", "getCountries"]),
  },
};
</script>

<style scoped>
.formulate-input-element > input {
  font-size: 1.125rem;
  line-height: 1.75rem;
  background-color: rgba(247, 247, 247, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 10px;
  padding-right: 10px;
}
.formulate-input-element > select {
  font-size: 1.125rem;
  line-height: 1.75rem;
  background-color: rgba(247, 247, 247, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 10px;
  padding-right: 10px;
}
.formulate-input-label .formulate-input-label--before {
  font-weight: 700;
}
</style>
